.ColMultiSelectorContainer {
  position: relative;
}
.ColMultiSelector {
  position: absolute;
  top: 28px;
  left: 0;
  z-index: 3;
  background: #ffffff;
  box-shadow: 0px 0px 50px rgba(105, 65, 199, 0.25);
  border-radius: 10px;
  transition: all 0.4s ease-in-out;
  box-sizing: border-box;
  max-height: 40px;
  overflow: hidden;
}

.ColMultiSelectorAllRow {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.ColMultiSelectorDivider {
  height: 1px;
  background: var(--primary-gradient);
}

.ColMultiSelectorRow {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.ColMultiSelectorRow label {
  white-space: nowrap;
}

.ColMultiSelectorInner {
  padding: 10px;
}

.ColMultiSelector.close {
  max-height: 0;
}

.ColMultiSelector label {
  font-size: 12px;
  padding: 5px;
  line-height: 120%;
  font-weight: 400;
}

.ColMultiSelector input[type='checkbox'] {
  cursor: pointer;
  display: grid;
  appearance: none;
  place-content: center;
}

.ColMultiSelector input[type='checkbox']::before {
  content: '';
  width: 12px;
  height: 12px;
  border-radius: 6px;
  background: white;
  border: 1px solid var(--primary-color);
}

.ColMultiSelector input[type='checkbox']:checked:before {
  content: '';
  width: 12px;
  height: 12px;
  mask-size: cover;
  background: var(--primary-color);
  mask: url('./check.svg');
}

.ColMultiSelector input[type='checkbox']:hover::before {
  background: var(--primary-color-light);
}

.ColMultiSelector
  .ColMultiSelectorAllRow
  input[type='checkbox']:checked:before {
  mask: url('./all_check.svg');
}

.ColMultiSelectorContainer .active-multi-filter-circle {
  position: absolute;
  top: 4px;
  right: 4px;
  width: 7px;
  height: 7px;
  border: 1px solid white;
  border-radius: 50%;
  background: #bfa4fe;
}
