:root {
  --primary-color: #6941c7;
  --primary-color-light: #bfa4fe80;
  --primary-color-light-pure: #bfa4fe;
  --primary-gradient: linear-gradient(110.96deg, #6941c7 0%, #2a0a74 50.71%);
  --primary-gradient-btn: linear-gradient(111.34deg, #6941c7 0%, #2a0a74 100%);
  --primary-gradient-btn-hover: linear-gradient(
    110.96deg,
    #6941c7 0%,
    #2a0a74 50.71%
  );
}

body {
  background: var(--primary-gradient) fixed;
  font-family: 'Epilogue', sans-serif;
}

.text-av-primary {
  color: var(--primary-color);
}

.text-av-primary-light-pure {
  color: var(--primary-color-light-pure);
}

.text-white{
  color: white;
}

.form-control:focus {
  border-color: var(--primary-color);
  box-shadow: 0 0 0 0.25rem rgb(105 65 199 / 25%);
}

.av-btn {
  border-radius: 10px;
  font-weight: bold;
  /* transition: all 1s ease-in !important; */
  background: var(--primary-gradient-btn);
  color: white;
  box-shadow: none;
  outline: none;
  border: 0;
}
.av-btn:focus,
.av-btn:hover {
  color: white;
  background: var(--primary-gradient-btn-hover);
  box-shadow: none;
  outline: none;
  border: 0;
}
.login-btn .log-in-txt-s {
  transition: margin 0.2s ease-out;
}
.login-btn .log-in-txt-3 {
  transition: margin 0.2s ease-out;
}
.login-btn:hover .log-in-txt-s {
  margin-right: 5px;
}
.login-btn:hover .log-in-txt-r {
  margin-left: 5px;
}

.badge {
  font-weight: normal;
  font-size: 0.92rem;
  color: black;
}
.bg-blue-gradient {
  background: linear-gradient(90deg, #afe7ff 19.91%, #31c1ff 100%);
}
.bg-yellow-gradient {
  background: linear-gradient(90deg, #fdff9d 19.91%, #fff500 100%);
}
.bg-orange-gradient {
  background: linear-gradient(90deg, #f9d287 19.91%, #f8851d 100%);
}
.bg-green-gradient {
  background: linear-gradient(90deg, #b1ffe7 19.91%, #00e49f 100%);
}

.cursor-pointer {
  cursor: pointer;
}

a.dark-link {
  color: var(--bs-gray-dark) !important;
  text-decoration: none;
}
a.dark-link:visited {
  color: var(--bs-gray-dark) !important;
}

a.dark-link:hover {
  color: var(--bs-purple) !important;
  /* color: red;#var(--bs-purple); */
}

.av-navbar {
  background: transparent !important;
  padding-bottom: 1rem;
  padding-top: 1rem;
}
.av-content {
  background-color: white;
  padding-top: 20px;
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
}
.av-navbar .me-auto.navbar-nav .nav-item {
  margin-right: 1rem;
}
.av-navbar .me-auto.navbar-nav .nav-item:last-child {
  margin-right: 0;
}
.av-navbar .nav-item > .nav-link {
  color: white !important;
  border-radius: 10px;
  border: 1px solid white;
}
.av-navbar .nav-item > .nav-link:hover {
  background: rgba(255, 255, 255, 0.2);
}
.av-navbar .nav-item > .nav-link:hover {
  color: white;
}
.av-navbar .nav-item > .nav-link.active {
  background: white;
  color: var(--primary-color) !important;
}

.dropdown-item {
  color: var(--primary-color) !important;
}

.table {
  background-color: white;
}

.av-content .table-responsive {
  overflow-x: auto;
}
.av-content .table-responsive th {
  background-color: white;
  position: sticky;
  z-index: 1;
  top: 0;
}
.table > :not(:first-child) {
  border-top: 0;
}
.av-content .table thead > tr > :after {
  content: ' ';
  bottom: 0;
  left: 0;
  right: 0;
  position: absolute;
  border-bottom: 2px solid var(--primary-color);
}

@media (max-width: 767.98px) {
  .av-navbar .me-auto.navbar-nav .nav-item {
    margin-right: 0;
    margin-top: 1rem;
  }
  .av-navbar .navbar-nav .nav-item {
    margin-top: 1rem;
  }
  .av-navbar .nav-link {
    padding-left: 0.5rem;
  }
  /* No sticky on mobile 4 now */
  .av-content .table thead > tr > :after {
    display: none;
  }
  .av-content .table-responsive {
    overflow-x: auto;
  }
  .av-content .table-responsive th {
    position: initial;
  }
  .table > :not(:first-child) {
    border-top: 2px solid var(--primary-color);
  }
}

.av-btn-icon-circle {
  transition: background 0.2s ease-in;
}
.av-btn-icon-circle > i {
  transition: color 0.2s ease-in;
}

.av-btn-icon-circle {
  border-radius: 13px;
  border: 1px solid var(--primary-color);
  color: var(--primary-color);
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0;
  height: 26px;
  width: 26px;
  background: transparent;
}
.av-btn-icon-circle:hover {
  background-color: var(--primary-color);
}
.av-btn-icon-circle:hover {
  color: inherit;
}

.av-btn-icon-circle > i {
  height: 20px;
}
.av-btn-icon-circle:hover > i {
  color: white;
}

.page-item.active .page-link {
  border-color: var(--primary-color);
  background-color: var(--primary-color);
}

.page-link {
  color: var(--primary-color);
}
.page-link:hover {
  color: var(--primary-color);
}

input[type='checkbox'].av-checkbox {
  cursor: pointer;
  display: grid;
  appearance: none;
  place-content: center;
}

input[type='checkbox'].av-checkbox::before {
  content: '';
  width: 20px;
  height: 20px;
  border-radius: 10px;
  background: white;
  border: 1px solid var(--primary-color);
}

input[type='checkbox'].av-checkbox:checked:before {
  content: '';
  width: 20px;
  height: 20px;
  mask-size: cover;
  background: var(--primary-color);
  mask: url('./assets/check.svg');
}

input[type='checkbox'].av-checkbox:hover::before {
  background: var(--primary-color-light);
}

.av-btn-primary-flat {
  padding: 8px 25px;
  border: 1px solid white;
  border-radius: 10px;
  background-color: var(--primary-color);
  color: white;
}
.av-btn-primary-flat:hover {
  color: white;
}

.av-btn-secondary-flat {
  padding: 8px 25px;
  border: 1px solid var(--primary-color);
  border-radius: 10px;
  background-color: white;
  color: var(--primary-color);
}
.av-btn-secondary-flat:hover {
  color: var(--primary-color);
}

.av-icon-alpha-sort {
  display: inline-block;
  width: 1rem;
  background-color: var(--primary-color);
  mask-size: cover;
  mask: url('./assets/alpha_sort.svg') no-repeat;
  transition: color 0.2s ease-in;
}

.av-icon-alpha-sort::before {
  content: '';
  display: block;
  padding-top: 100%;
}

.av-btn-icon-circle:hover > .av-icon-alpha-sort {
  background-color: white;
}

.av-icon-sort {
  display: inline-block;
  width: 1rem;
  background-color: var(--primary-color);
  mask-size: cover;
  mask: url('./assets/sort.svg') no-repeat;
  transition: color 0.2s ease-in;
}

.av-icon-sort::before {
  content: '';
  display: block;
  padding-top: 100%;
}

.av-btn-icon-circle:hover > .av-icon-sort {
  background-color: white;
}

th.col-more-zindex {
  z-index: 2 !important;
}


.delivery-timeline {
  border-radius: 100px;
  background: rgba(105, 65, 199, 0.1);
  width: 288px;
  height: 27px;
  position: relative;
}


.delivery-timeline-remaining {
  position: absolute;
  right: 0;
  top: 0;
  bottom: 0;
  width: 50%;
  border-radius: 100px;
  text-align: right;
  line-height: 27px;

}

.delivery-timeline-remaining-value {
  position: absolute;
  right: 0;
  top: 0;
  bottom: 0;
  width: 50%;
  border-radius: 100px;
  text-align: right;
  line-height: 27px;
  padding-right:10px;
  font-size:14px;
}

.preview-video {
  max-height: 80vh;
  width: 100%;
}

.mfs-12 {
  font-size: 12px;
}

.mfs-18 {
  font-size: 18px;
}