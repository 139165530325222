.ColDateRangeContainer {
  position: relative;
}

.ColDateRangeContainerLabel {
  white-space: nowrap;
}

.ColDateRange {
  position: absolute;
  top: 28px;
  left: 0;
  z-index: 4;
  max-height: 350px;
  background: #ffffff;
  box-shadow: 0px 0px 50px rgba(105, 65, 199, 0.25);
  border-radius: 10px;
  transition: all 0.4s ease-in-out;
  box-sizing: border-box;
  overflow: hidden;
}

.ColDateRangeContainer .active-multi-filter-circle {
  position: absolute;
  top: 4px;
  right: 4px;
  width: 7px;
  height: 7px;
  border: 1px solid white;
  border-radius: 50%;
  background: #bfa4fe;
}

.ColDateRangeReset {
  position: absolute;
  left: 290px;
  top: 14px;
}

.rdrDefinedRangesWrapper {
  display: none;
}

.ColDateRange.close {
  max-height: 0;
}

.rdrInRange {
  color: rgba(191, 164, 254, 0.5) !important;
}

.rdrDay:not(.rdrDayPassive) {
  color: var(--primary-color);
}

.rdrStartEdge,
.rdrEndEdge {
  background: var(--primary-color) !important;
  color: white !important;
}

.rdrInRange ~ .rdrDayNumber span {
  color: var(--primary-color) !important;
}

.rdrDateDisplayItemActive input {
  color: var(--primary-color);
}

.rdrDateDisplayItem input {
  color: var(--primary-color);
}

.rdrDateDisplayItemActive {
  border-color: var(--primary-color);
}

.rdrDayPassive {
  opacity: 0.5;
}

.rdrDateDisplay {
  width: calc(100% - 65px);
}

.rdrDayStartPreview, .rdrDayInPreview, .rdrDayEndPreview {
  color: var(--primary-color) !important;
}

.rdrDayToday .rdrDayNumber span:after {
  background: var(--primary-color) !important;
}